import React, { Component } from "react";
import NavigationMenu from "./layouts/NavigationMenu";
import PromotionPage from "./layouts/promotion";
import ChildGrowthPage from "./layouts/childGrowth";
import CaregiverPage from "./layouts/caregiver";
import ClinicalAllergyPage from "./layouts/clinicalAllergy";
import ClinicalMedicationPage from "./layouts/clinicalMedication";
import ClinicalAppointmentPage from "./layouts/clinicalAppointment";
import ClinicalVaccinePage from "./layouts/clinicalVaccine";
import MyFamilyDoctorPage from "./layouts/myFamilyDoctor";
import MedicalCertificatePage from "./layouts/medicalCertificate";
import PersonalFolderPage from "./layouts/personalFolder";
import CBHRPage from "./layouts/cbhr";
import HealthcareDocumentPage from "./layouts/healthcareDocument";
import ClinicalInvestigationPage from "./layouts/clinicalInvestigation";
import DoctorSearchPage from "./layouts/doctorSearch";
import HealthManagementPage from "./layouts/healthManagement";
import HealthNewsPage from "./layouts/healthNews";
import HealthProgramPage from "./layouts/healthProgram";
import QRCodeScannerPage from "./layouts/qrcodeScanner";
import PromulgationPage from "./layouts/promulgation";
import PromotionTruck from "./layouts/promulgation/promotionTruck";
import PromotionalVideos from "./layouts/promulgation/promotionalVideos";
import PromulgationAdvertisement from "./layouts/promulgation/promulgation-advertisement";
import InteractivePictureBook from "./layouts/promulgation/promulgation-picture-book";
import PromulgationAnimation from "./layouts/promulgation/promulgation-animation";
import PromulgationSupportStation from "./layouts/promulgation/promulgation-support-station";
import Covid19QRPage from "./layouts/covid19QR";
import Covid19MinorsPage from "./layouts/covid19Minors";
import EhealthProPage from "./layouts/ehealthPro";
import EhealthProAccMgmtPage from "./layouts/ehealthProAccMgmt";
import EhealthProInfoSharingPage from "./layouts/ehealthProInfoSharing";
import EhealthProNotiCenter from "./layouts/ehealthProNotiCenter";
//import VaccineCalculator from "./layouts/vaccineCalculator";
//import VaccineCalResult from "./layouts/vaccineCalResult";
//import VaccineCalResultChild from "./layouts/vaccineCalResultChild";
import MaintenancePage from "./layouts/maintenance";
import PrivacyPage from "./layouts/privacyProtection";
import EppPrivacyPage from "./layouts/eppPrivacy";
import TermsOfUse from "./layouts/termsOfUse";
import DownloadStickerPage from "./layouts/downloadSticker";
import DownloadBgPage from "./layouts/downloadBg";
import EhealthFAQ from "./layouts/ehealthFaq";
import EhealthProFAQ from "./layouts/ehealthProFaq";
import PrivacyGamePage from "./layouts/privacyGame";
import NotFoundPage from "./layouts/notFoundPage";
import DownloadAPK from "./layouts/donwloadAPK";
import LifeOverview from "./layouts/elife/overview";
import LifeLeaderboard from "./layouts/elife/elifeLeaderBoard";
import LifeTutorial from "./layouts/elife/tutorial";
import LifeCoins from "./layouts/elife/coins";
import LifeParkList from "./layouts/elife/elifeParklist";
import LifeFaq from "./layouts/elife/faq";
//import LifeStepChallenge from "./layouts/elife/stepChallenge";
import LifeRedemption from "./layouts/elife/redemption";
import { IntlProvider } from "react-intl";
import { Route, withRouter, Switch } from "react-router-dom";
import "./resources/styles/bootstrap.min.scss";
import "./resources/styles/sliderAnimation.scss";
import "./resources/styles/responsiveMenu.scss";
import "./resources/styles/style.scss";
import messages from "./resources/languages";
//import { getCalculatorSettings } from "./apis";
import { isIEBrowser } from "./resources/utils/commonUtils";

class App extends Component {
  state = {
    locale: "zh-HK",
    vaccineCalcResult: null,
    serverDtm: null,
  };

  static defaultProps = {
    languageCodeMap: {
      en: {
        code: "en",
        title: "English",
        buttonText: "EN",
        buttonAlt: "English. Click here to switch to English page",
        buttonID: "enBtn",
        mobileButtonID: "enMobileBtn",
      },
      "zh-HK": {
        code: "tc",
        title: "繁體",
        buttonText: "繁",
        buttonAlt: "繁體，點擊以轉換為繁體頁面",
        buttonID: "tcBtn",
        mobileButtonID: "tcMobileBtn",
      },
      "zh-CN": {
        code: "sc",
        title: "简体",
        buttonText: "简",
        buttonAlt: "简体，点击以转换为简体页面",
        buttonID: "scBtn",
        mobileButtonID: "scMobileBtn",
      },
    },
  };

  componentDidMount() {
    let search =
      this.props.location.search || window.location.hash.split("?")[1];
    let pathname = this.props.location.pathname;
    const param = new URLSearchParams(search);
    const langParam = param.get("lang");

    let language;
    if (langParam && langParam === "en_US") {
      language = "en";
      this.props.history.push(
        pathname + search.replace("lang=en_US", "lang=en")
      );
    } else if (langParam && langParam === "zh_HK") {
      language = "zh-HK";
      this.props.history.push(
        pathname + search.replace("lang=zh_HK", "lang=tc")
      );
    } else if (langParam && langParam === "zh_CN") {
      language = "zh-CN";
      this.props.history.push(
        pathname + search.replace("lang=zh_CN", "lang=sc")
      );
    } else if (langParam && langParam === "en") {
      language = "en";
    } else if (langParam && langParam === "zh-HK") {
      language = "zh-HK";
    } else if (langParam && langParam === "tc") {
      language = "zh-HK";
    } else if (langParam && langParam === "zh-CN") {
      language = "zh-CN";
    } else if (langParam && langParam === "sc") {
      language = "zh-CN";
    } else {
      if (
        localStorage.getItem("lang") &&
        localStorage.getItem("lang") !== "null"
      ) {
        language = localStorage.getItem("lang");
      } else {
        console.log("else");
        let lang = navigator.language || navigator.userLanguage;
        if (
          lang === "zh-HK" ||
          lang === "zh-TW" ||
          lang === "zh" ||
          lang === "zh-CN"
        ) {
          language = "zh-HK";
        } else {
          language = "en";
        }
      }
    }
    this.changeLanguage(language);

    // Redirect to maintenance page with unsupport browser message
    const isIe = isIEBrowser();
    if (isIe) {
      this.props.history.push("maintenance");
    }

    // Get vaccine calculator settings api when page load
    //this.callGetCalSettingsAPI();
  }

  setVaccineCalcResult = (vaccineCalcResult) => {
    this.setState({ vaccineCalcResult });
  };

  changeLanguage = (locale) => {
    localStorage.setItem("lang", locale);
    document.documentElement.lang = locale;
    this.setState({
      locale: locale,
    });
  };

  getCurrentLang() {
    if (localStorage.getItem("lang") === "zh-CN") {
      this.state.lang = "sc";
    } else if (localStorage.getItem("lang") === "zh-HK") {
      this.state.lang = "tc";
    } else {
      this.state.lang = "en";
    }
    console.log("this.state.lang: " + this.state.lang);
  }

  /*async callGetCalSettingsAPI() {
    this.getCurrentLang();
    let result;
    try {
      result = await getCalculatorSettings(this.state.lang);
    } catch (err) {
      console.log("err: " + err);
    } finally {
      if (result && result.data) {
        const responseDtm = result.headers.date;
        const isIe = isIEBrowser();
        let responseLocalDtm = "";
        if (isIe) {
          // as IE doesn't support HK timezone, server date will be calculate by UTC server date
          var date = new Date(responseDtm);
          var now_utc = `${date.getUTCFullYear()}-${
            date.getUTCMonth() + 1
          }-${date.getUTCDate()} ${
            date.getUTCHours() + 8
          }:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
          responseLocalDtm = new Date(now_utc);
        } else {
          responseLocalDtm = new Date(responseDtm).toLocaleString("en-US", {
            timeZone: "Asia/Hong_Kong",
          });
        }
        this.setState({
          vaccineCalculatorSettings: result.data,
          serverDtm: new Date(responseLocalDtm),
        });
      } else {
        //err
      }
    }
  }*/

  render() {
    //const { locale, vaccineCalcResult } = this.state;
    const { locale } = this.state;
    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <NavigationMenu
          locale={locale}
          changeLanguage={this.changeLanguage}
          languageCodeMap={this.props.languageCodeMap}
          //vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
        />
        <Switch>
          <Route
            exact
            path="/covid19minors"
            render={() => (
              <Covid19MinorsPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/covid19QR"
            render={() => (
              <Covid19QRPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/qrcode-licence-scanner"
            render={() => (
              <QRCodeScannerPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/health-program"
            render={() => (
              <HealthProgramPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/health-news"
            render={() => (
              <HealthNewsPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/health-management"
            render={() => (
              <HealthManagementPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/doctor-search"
            render={() => (
              <DoctorSearchPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/clinical-vaccine"
            render={() => (
              <ClinicalVaccinePage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/my-family-doctor"
            render={() => (
              <MyFamilyDoctorPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/healthcare-document"
            render={() => (
              <HealthcareDocumentPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/medical-certificate"
            render={() => (
              <MedicalCertificatePage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/clinical-investigations"
            render={() => (
              <ClinicalInvestigationPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/clinical-appointment"
            render={() => (
              <ClinicalAppointmentPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/clinical-allergy"
            render={() => (
              <ClinicalAllergyPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/clinical-medication"
            render={() => (
              <ClinicalMedicationPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/caregiver"
            render={() => (
              <CaregiverPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/child-growth"
            render={() => (
              <ChildGrowthPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          {/*<Route
            exact
            path="/vaccinepasscalculator"
            render={() => (
              <VaccineCalculator
                locale={locale}
                changeLanguage={this.changeLanguage}
                setVaccineCalcResult={this.setVaccineCalcResult}
                languageCodeMap={this.props.languageCodeMap}
                vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
                serverDtm={this.state.serverDtm}
              />
            )}
          />
          <Route
            exact
            path="/vaccine-calculator-result"
            render={() => (
              <VaccineCalResult
                locale={locale}
                //vaccineCalcResult={vaccineCalcResult}
                setVaccineCalcResult={this.setVaccineCalcResult}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
                vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
                serverDtm={this.state.serverDtm}
              />
            )}
          />
          <Route
            exact
            path="/vaccine-calculator-result-child"
            render={() => (
              <VaccineCalResultChild
                locale={locale}
                //vaccineCalcResult={vaccineCalcResult}
                setVaccineCalcResult={this.setVaccineCalcResult}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
                vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
                serverDtm={this.state.serverDtm}
              />
            )}
            /> */}
          <Route
            exact
            path="/index.html"
            render={() => (
              <PromotionPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={() => (
              <PromotionPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/maintenance"
            render={() => (
              <MaintenancePage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
                //vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
              />
            )}
          />
          <Route
            exact
            path="/privacy-protection"
            render={() => (
              <PrivacyPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/privacy-policy"
            render={() => (
              <EppPrivacyPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/terms-of-use"
            render={() => (
              <TermsOfUse
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
            />
          <Route
            exact
            path="/privacy-game"
            render={() => (
              <PrivacyGamePage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/download-sticker"
            render={() => (
              <DownloadStickerPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/download-bg"
            render={() => (
              <DownloadBgPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/download-apk"
            render={() => (
              <DownloadAPK
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/ehealth-faq"
            render={() => (
              <EhealthFAQ
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/ehealth-pro"
            render={() => (
              <EhealthProPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/ehealth-pro-account-management"
            render={() => (
              <EhealthProAccMgmtPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/ehealth-pro-information-sharing"
            render={() => (
              <EhealthProInfoSharingPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/ehealth-pro-notification-center"
            render={() => (
              <EhealthProNotiCenter
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/ehealth-pro-faq"
            render={() => (
              <EhealthProFAQ
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/promulgation"
            render={() => (
              <PromulgationPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/promulgation-promotion-truck"
            render={() => (
              <PromotionTruck
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/promulgation-promotional-videos"
            render={() => (
              <PromotionalVideos
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/promulgation-advertisement"
            render={() => (
              <PromulgationAdvertisement
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/promulgation-picture-book"
            render={() => (
              <InteractivePictureBook
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/promulgation-support-station"
            render={() => (
              <PromulgationSupportStation
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/personal-folder"
            render={() => (
              <PersonalFolderPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/cross-boundary-health-record"
            render={() => (
              <CBHRPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/promulgation-animation"
            render={() => (
              <PromulgationAnimation
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/elife-overview"
            render={() => (
              <LifeOverview
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/elife-leaderboard"
            render={() => (
              <LifeLeaderboard
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/elife-tutorial"
            render={() => (
              <LifeTutorial
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/elife-coins"
            render={() => (
              <LifeCoins
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/elife-parklist"
            render={() => (
              <LifeParkList
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/elife-faq"
            render={() => (
              <LifeFaq
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          {/*<Route
            exact
            path="/elife-step-challenge"
            render={() => (
              <LifeStepChallenge
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
            />*/}
          <Route
            exact
            path="/elife-redemption"
            render={() => (
              <LifeRedemption
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/covid19minors"
            render={() => (
              <Covid19MinorsPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/covid19QR"
            render={() => (
              <Covid19QRPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/qrcode-licence-scanner"
            render={() => (
              <QRCodeScannerPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/health-program"
            render={() => (
              <HealthProgramPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/health-news"
            render={() => (
              <HealthNewsPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/health-management"
            render={() => (
              <HealthManagementPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/doctor-search"
            render={() => (
              <DoctorSearchPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/clinical-vaccine"
            render={() => (
              <ClinicalVaccinePage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/clinical-investigations"
            render={() => (
              <ClinicalInvestigationPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/clinical-appointment"
            render={() => (
              <ClinicalAppointmentPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/clinical-allergy"
            render={() => (
              <ClinicalAllergyPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/clinical-medication"
            render={() => (
              <ClinicalMedicationPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/caregiver"
            render={() => (
              <CaregiverPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/child-growth"
            render={() => (
              <ChildGrowthPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          {/* <Route
            exact
            path="/app/vaccinepasscalculator"
            render={() => (
              <VaccineCalculator
                locale={locale}
                changeLanguage={this.changeLanguage}
                setVaccineCalcResult={this.setVaccineCalcResult}
                languageCodeMap={this.props.languageCodeMap}
                vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
                serverDtm={this.state.serverDtm}
              />
            )}
          />
          <Route
            exact
            path="/app/vaccine-calculator-result"
            render={() => (
              <VaccineCalResult
                locale={locale}
                //vaccineCalcResult={vaccineCalcResult}
                setVaccineCalcResult={this.setVaccineCalcResult}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
                vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
                serverDtm={this.state.serverDtm}
              />
            )}
          />
          <Route
            exact
            path="/app/vaccine-calculator-result-child"
            render={() => (
              <VaccineCalResultChild
                locale={locale}
                //vaccineCalcResult={vaccineCalcResult}
                setVaccineCalcResult={this.setVaccineCalcResult}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
                vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
                serverDtm={this.state.serverDtm}
              />
            )}
            /> */}
          <Route
            exact
            path="/app/index.html"
            render={() => (
              <PromotionPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/"
            render={() => (
              <PromotionPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/maintenance"
            render={() => (
              <MaintenancePage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
                //vaccineCalculatorSettings={this.state.vaccineCalculatorSettings}
              />
            )}
          />
          <Route
            exact
            path="/app/privacy-protection"
            render={() => (
              <PrivacyPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/privacy-policy"
            render={() => (
              <EppPrivacyPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/terms-of-use"
            render={() => (
              <TermsOfUse
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
            />
          <Route
            exact
            path="/app/download-sticker"
            render={() => (
              <DownloadStickerPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/download-bg"
            render={() => (
              <DownloadBgPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/app/ehealth-faq"
            render={() => (
              <EhealthFAQ
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/ehealth-pro"
            render={() => (
              <EhealthProPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/ehealth-pro-account-management"
            render={() => (
              <EhealthProAccMgmtPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/ehealth-pro-information-sharing"
            render={() => (
              <EhealthProInfoSharingPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/ehealth-pro-notification-center"
            render={() => (
              <EhealthProNotiCenter
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/ehealth-pro-faq"
            render={() => (
              <EhealthProFAQ
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation"
            render={() => (
              <PromulgationPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation-promotion-truck"
            render={() => (
              <PromotionTruck
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation-promotional-videos"
            render={() => (
              <PromotionalVideos
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation-advertisement"
            render={() => (
              <PromulgationAdvertisement
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation-picture-book"
            render={() => (
              <InteractivePictureBook
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation-animation"
            render={() => (
              <PromulgationAnimation
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/promulgation-support-station"
            render={() => (
              <PromulgationSupportStation
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/app/elife-overview"
            render={() => (
              <LifeOverview
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/app/elife-leaderboard"
            render={() => (
              <LifeLeaderboard
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/app/elife-tutorial"
            render={() => (
              <LifeTutorial
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/app/elife-coins"
            render={() => (
              <LifeCoins
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="/app/elife-parklist"
            render={() => (
              <LifeParkList
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          <Route
            exact
            path="/app/elife-faq"
            render={() => (
              <LifeFaq
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
          {/*<Route
            exact
            path="/app/elife-step-challenge"
            render={() => (
              <LifeStepChallenge
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
            />*/}

          <Route
            exact
            path="/app/elife-redemption"
            render={() => (
              <LifeRedemption
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />

          <Route
            exact
            path="*"
            render={() => (
              <NotFoundPage
                locale={locale}
                changeLanguage={this.changeLanguage}
                languageCodeMap={this.props.languageCodeMap}
              />
            )}
          />
        </Switch>
      </IntlProvider>
    );
  }
}

// extended main view with translate hoc
export default withRouter(App);
