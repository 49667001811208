import React, { Component, Fragment } from "react";
import load from "../ImageLoder";
import PageHOC from "../PageHOC";
import { NavLink } from "react-router-dom";
import { isIEBrowser } from "../resources/utils/commonUtils";

class NavigationMenu extends Component {
  targetElement = null;

  constructor() {
    super();

    this.state = {
      showMenu: false,
      showMenuCovid19: false,
      showMobileMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.showMenuCovid19 = this.showMenuCovid19.bind(this);
    this.closeMenuCovid19 = this.closeMenuCovid19.bind(this);
    this.showMenuPro = this.showMenuPro.bind(this);
    this.closeMenuPro = this.closeMenuPro.bind(this);
    this.showMenuLife = this.showMenuLife.bind(this);
    this.closeMenuLife = this.closeMenuLife.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.initPage = this.initPage.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  closeMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: false });
  }

  showMenuCovid19(event) {
    event.preventDefault();
    this.setState({ showMenuCovid19: true });
  }

  closeMenuCovid19(event) {
    event.preventDefault();
    this.setState({ showMenuCovid19: false });
  }

  showMenuPro(event) {
    event.preventDefault();
    this.setState({ showMenuPro: true });
  }

  closeMenuPro(event) {
    event.preventDefault();
    this.setState({ showMenuPro: false });
  }
  showMenuLife(event) {
    event.preventDefault();
    this.setState({ showMenuLife: true });
  }

  closeMenuLife(event) {
    event.preventDefault();
    this.setState({ showMenuLife: false });
  }

  componentDidMount() {
    this.initPage();
    window.addEventListener("resize", this.initPage);
    this.targetElement = document.getElementById("header");
  }

  initPage = () => {
    //scroll show nav
    let ProNav = document.getElementById("ProNav");

    document.body.onclick = (e) => {
      const MainContent = document.getElementById("MainContent");
      if (!MainContent) {
        return;
      }
      const isChild = MainContent.contains(e.target);
      if (this.state.showMobileMenu && isChild) {
        this.closeMobileMenu();
      }
    };

    // window.addEventListener('scroll',(e)=>{
    //     const offset = window.scrollY;
    //     if(offset>100){
    //         ProNav.className = "promotion-nav scroll-down";
    //     }else{
    //         ProNav.className = "promotion-nav";

    //     }
    //  });

    window.addEventListener("scroll", function () {
      let offset =
        document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
      if (offset > 100) {
        ProNav.className = "promotion-nav scroll-down";
      } else {
        ProNav.className = "promotion-nav";
      }
    });
  };

  settabIndex(tabIndex) {
    const mainWithtabIndexItems = document
      .getElementById("MainContent")
      .querySelectorAll("[tabIndex]");
    const youtubePlayerLogo = document.getElementsByClassName(
      "ytp-title-channel-logo"
    );
    const youtubePlayerMore = document.getElementsByClassName("ytp-button");
    mainWithtabIndexItems.forEach((e) => (e.tabIndex = tabIndex));
    let dataLayerItems = document.querySelectorAll("[data-layer]");

    dataLayerItems.forEach((e) => (e.tabIndex = tabIndex));

    // if(youtubePlayerLogo){
    //     console.log(youtubePlayerLogo)
    // }
    // if(youtubePlayerMore){
    //     console.log(youtubePlayerLogo)
    // }
  }

  setOverflowY(overflowY) {
    document.body.style.overflowY = overflowY;
    let mainContain = document.getElementById("MainContent");
    if (mainContain) {
      mainContain.style.overflowY = overflowY;
    }
  }

  openMobileMenu = () => {
    //console.log('openMobileMenu');
    this.setState({ showMobileMenu: true });
    this.setOverflowY("hidden");
    this.settabIndex(-1);
    let mainContain = document.getElementById("MainContent");
    mainContain.style.position = "fixed";
    // let root = document.getElementsByTagName( 'html' )[0];
    // root.classList.add("open-menu");
    // let header = document.getElementById("header");
    // header.addEventListener('touchstart', function(e) {
    //     if( this.scrollTop === 0 ) {
    //         this.scrollTop += 1;
    //     } else if( this.scrollTop + this.offsetHeight >= this.scrollHeight ) {
    //         this.scrollTop -= 1;
    //     }
    // });
    //disableBodyScroll(this.targetElement);
  };

  closeMobileMenu = () => {
    //console.log('closeMobileMenu');
    this.setState({ showMobileMenu: false });
    this.setOverflowY("initial");
    let mainContain = document.getElementById("MainContent");
    mainContain.style.position = "relative";
    this.settabIndex(0);
    // let root = document.getElementsByTagName( 'html' )[0];
    // root.classList.remove("open-menu");
    //enableBodyScroll(this.targetElement);
  };

  onMobileNavAction = (e) => {
    e.stopPropagation();
    if (e.target.id === "mobileNav") {
      this.closeMobileMenu();
    }
  };

  onContianerAction = (e) => {
    if (e.target.id === "mobile-change-language") {
      this.closeMobileMenu();
    }
  };

  handleIEClick = (e) => {
    if (isIEBrowser()) {
      e.preventDefault();
    }
  };

  render() {
    const { locale, t, changeLanguage, vaccineCalculatorSettings } = this.props;
    const { languageCodeMap } = this.props;
    const languageButtons = [];
    const mobileLanguageButtons = [];
    const isIe = isIEBrowser();
    let tabIndex = 1;
    let ehealthWesbiteUrl = `https://www.ehealth.gov.hk/${languageCodeMap[locale]["code"]}/you-and-your-family/mobile-app.html`;
    for (let code in languageCodeMap) {
      languageButtons.push(
        <div
          key={`language-button-${code}`}
          onClick={() => {
            changeLanguage(code);
            //this.closeMobileMenu();
          }}
          id={languageCodeMap[code]["buttonID"]}
          lang={code}
          className={
            code === locale
              ? "each-language on selected-language"
              : "each-language"
          }
          title={languageCodeMap[code]["title"]}
          alt={languageCodeMap[code]["buttonAlt"]}
        >
          <a
            href="javascript:void(0);"
            lang={code}
            tabIndex="0"
            alt={languageCodeMap[code]["buttonAlt"]}
          >
            <div lang={code} alt={languageCodeMap[code]["buttonAlt"]}>
              {" "}
              {languageCodeMap[code]["buttonText"]}
              <img
                lang={code}
                src={load(`./resources/images/blank.png`)}
                alt={languageCodeMap[code]["buttonAlt"]}
              />
            </div>
          </a>
        </div>
      );
      mobileLanguageButtons.push(
        <div
          key={`language-button-${code}`}
          onClick={() => {
            changeLanguage(code);
            //this.closeMobileMenu();
          }}
          id={languageCodeMap[code]["mobileButtonID"]}
          lang={code}
          className={
            code === locale
              ? "each-language on selected-language"
              : "each-language"
          }
          title={languageCodeMap[code]["title"]}
          alt={languageCodeMap[code]["buttonAlt"]}
        >
          <a
            href="javascript:void(0);"
            lang={code}
            tabIndex="0"
            alt={languageCodeMap[code]["buttonAlt"]}
          >
            <div lang={code} alt={languageCodeMap[code]["buttonAlt"]}>
              {" "}
              {languageCodeMap[code]["buttonText"]}
              <img
                lang={code}
                src={load(`./resources/images/blank.png`)}
                alt={languageCodeMap[code]["buttonAlt"]}
              />
            </div>
          </a>
        </div>
      );
      tabIndex++;
    }
    return (
      <Fragment>
        <header
          id="header"
          className={`header ${
            this.state.showMobileMenu ? "mobileOverlay2" : ""
          }`}
        >
          <div className="header-container">
            <a
              href="#"
              alt={t("eHealth Website")}
              className={isIe ? "disabledLogo" : "logo"}
              onClick={
                isIe
                  ? this.handleIEClick
                  : () => {
                      window.open(ehealthWesbiteUrl, "_blank");
                    }
              }
            >
              <img
                lang="zh-HK"
                src={load(`./resources/images/logo_color.png`)}
                id="imgHeaderMenuLogo"
                alt={t(
                  "Electronic Health Record Sharing System 醫健通 E health"
                )}
              />
            </a>
            <div
              className="mobile-menuicon-container"
              alt={t("Mobile menu")}
              role="button"
              aria-label={t("Mobile menu")}
              onClick={() =>
                this.state.showMobileMenu
                  ? this.closeMobileMenu()
                  : this.openMobileMenu()
              }
            >
              <label
                className="menu-icon"
                id="btnHeaderOpenMenu"
                alt={t("Mobile menu")}
              >
                <span className="navicon"></span>
              </label>
            </div>
          </div>
          <div
            id="mobileNav"
            onClick={(e) => this.onMobileNavAction(e)}
            onTouchStart={(e) => this.onMobileNavAction(e)}
            className={`${this.state.showMobileMenu ? "show" : "hide"}`}
          >
            <ul
              className={`menu ${this.state.showMobileMenu ? "show" : "hide"}`}
            >
              <li>
                <NavLink
                  onClick={
                    isIe
                      ? this.handleIEClick
                      : () => {
                          this.closeMobileMenu();
                        }
                  }
                  exact
                  to="/index.html"
                  id="linkHeaderMobileMenuHome"
                  alt="點擊以返回主頁"
                  tabIndex="0"
                  className={isIe ? "disabledMobileItem" : ""}
                >
                  {t("Home")}
                </NavLink>
              </li>
              <div className="dropdown">
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenu(e)
                      : this.state.showMenu
                      ? this.closeMenu(e)
                      : this.showMenu(e);
                  }}
                  exact
                  to="/index.html"
                  id="btnHeaderMobileMenuOneStopService"
                  className={
                    isIe
                      ? "disabledMobileItem"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("Nav eHealth App")}
                >
                  {t("Nav eHealth App")} <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenu ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/health-management"
                      id="linkHeaderMobileMenuHealthManagement​"
                      alt={t(
                        "Health Management​, click here for more information"
                      )}
                    >
                      {t("Health Management​")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/cross-boundary-health-record"
                      id="linkHeaderMenuCBHR"
                      alt={t(
                        "Cross-boundary Health Record, click here for more information"
                      )}
                    >
                      {t("Cross-boundary Health Record")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/personal-folder"
                      id="linkHeaderMenuPersonalFolder"
                      alt={t(
                        "Personal Folder, click here for more information"
                      )}
                    >
                      {t("Personal Folder")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/clinical-vaccine"
                      id="linkHeaderMobileMenuVaccines"
                      alt={t("Vaccines, click here for more information")}
                    >
                      {t("Vaccines")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/clinical-appointment"
                      id="linkHeaderMobileMenuAppointment"
                      alt={t("Appointment, click here for more information")}
                    >
                      {t("Appointment")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/clinical-allergy"
                      id="linkHeaderMobileMenuAllergy"
                      alt={t("Allergy, click here for more information")}
                    >
                      {t("Allergy")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/clinical-medication"
                      id="linkHeaderMobileMenuMedications"
                      alt={t("Medications, click here for more information")}
                    >
                      {t("Medications")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/clinical-investigations"
                      id="linkHeaderMobileMenuInvestigations"
                      alt={t("Investigations, click here for more information")}
                    >
                      {t("Investigations")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/health-news"
                      id="linkHeaderMobileMenuHealthNews"
                      alt={t("Health news, click here for more information")}
                    >
                      {t("Health News")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/doctor-search"
                      id="linkHeaderMobileMenuDoctorSearch"
                      alt={t("Doctor search, click here for more information")}
                    >
                      {t("Doctor Search")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/child-growth"
                      id="linkHeaderMobileMenuChildGrowthRecord"
                      alt={t(
                        "Child growth record, click here for more information"
                      )}
                    >
                      {t("Child Growth Record")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/health-program"
                      id="linkHeaderMobileMenuHealthProgramme"
                      alt={t(
                        "Health programme, click here for more information"
                      )}
                    >
                      {t("Health Programme")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/caregiver"
                      id="linkHeaderMobileMenuMyFamily"
                      alt={t("My family, click here for more information")}
                    >
                      {t("My Family")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/my-family-doctor"
                      id="linkHeaderMobileMenuMyFamilyDoctor"
                      alt={t(
                        "My Family Doctor, click here for more information"
                      )}
                    >
                      {t("My Family Doctor")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/healthcare-document"
                      id="linkHeaderMobileMenuHealthcareDocument"
                      alt={t(
                        "Healthcare document, click here for more information"
                      )}
                    >
                      {t("Healthcare Document")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/medical-certificate"
                      id="linkHeaderMobileMenuMedicalCertificate"
                      alt={t(
                        "Medical Certificate, click here for more information"
                      )}
                    >
                      {t("Medical Certificate")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/qrcode-licence-scanner"
                      id="linkHeaderMenuQRcodeScanner"
                      alt={t(
                        "QR Code / Licence Scanner, click here for more information"
                      )}
                    >
                      {t("QR Code / Licence Scanner main menu")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* <div className="dropdown">
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenuCovid19(e)
                      : this.state.showMenuCovid19
                      ? this.closeMenuCovid19(e)
                      : this.showMenuCovid19(e);
                  }}
                  exact
                  to="/index.html"
                  id="btnHeaderMobileMenuOneStopService"
                  className={
                    isIe
                      ? "disabledMobileItem"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("COVID-19 Vaccination Records")}
                >
                  {t("COVID-19 Vaccination Records")}{" "}
                  <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenuCovid19 ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/covid19qr"
                      id="linkHeaderMobileMenuQR"
                      alt={t("Vaccination Record QR Code")}
                    >
                      {t("Vaccination Record QR Code")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/covid19minors"
                      id="linkHeaderMobileMenuAdolescents"
                      role="text"
                      aria-label={t("Vaccination Record - Adolescents ALT")}
                      alt={t("Vaccination Record - Adolescents ALT")}
                    >
                      {t("Vaccination Record - Adolescents")}
                    </NavLink>
                  </li>
                  {vaccineCalculatorSettings &&
                    vaccineCalculatorSettings.enableButtonFlag === "Y" && (
                      <li>
                        <NavLink
                          onClick={() => this.closeMobileMenu()}
                          exact
                          to="/vaccinepasscalculator"
                          id="linkHeaderMobileMenuCalculator"
                          className="header-menu-item"
                          alt=""
                        >
                          {t("Vaccine Pass Calculator")}
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div> */}

              <div className="dropdown">
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenuPro(e)
                      : this.state.showMenuPro
                      ? this.closeMenuPro(e)
                      : this.showMenuPro(e);
                  }}
                  exact
                  to="/index.html"
                  id=""
                  className={
                    isIe
                      ? "disabledMobileItem"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("eHealth Pro")}
                >
                  {t("eHealth Pro")} <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenuPro ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro"
                      id="linkHeaderMobileMenuProOverview"
                      alt={t("Pro Overview")}
                    >
                      {t("Pro Overview")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro-account-management"
                      id="linkHeaderMobileMenuProAccMgmt"
                      alt={t("Pro Account Management")}
                    >
                      {t("Pro Account Management")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro-information-sharing"
                      id="linkHeaderMobileMenuProInfoShare"
                      alt={t("Pro Information Sharing")}
                    >
                      {t("Pro Information Sharing")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro-notification-center"
                      id="linkHeaderMobileMenuProNotiCenter"
                      alt={t("Pro Notification Center")}
                    >
                      {t("Pro Notification Center")}
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="dropdown">
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenuLife(e)
                      : this.state.showMenuLife
                      ? this.closeMenuLife(e)
                      : this.showMenuLife(e);
                  }}
                  exact
                  to="/index.html"
                  id=""
                  className={
                    isIe
                      ? "disabledMobileItem"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("Nav e+Life App")}
                >
                  {t("Nav e+Life App")} <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenuLife ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-overview"
                      id="linkHeaderMenuLifeOveriew"
                      alt={t("e+Life Overview")}
                    >
                      {t("e+Life Overview")}
                    </NavLink>
                  </li>
                  <li className="no-active-status">
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-overview#overview-park-container"
                      id="linkHeaderMenuLifeOveriew"
                      alt={t("e+ Go to Park")}
                    >
                      {t("e+ Go to Park")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-tutorial"
                      id="linkHeaderMenuLifeTutorial"
                      alt={t("e+Life Tutorial")}
                    >
                      {t("e+Life Tutorial")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-coins"
                      id="linkHeaderMenuLifeEarnPoints"
                      alt={t("e+Life Coins")}
                    >
                      {t("e+Life Coins")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-redemption"
                      id="linkHeaderMenuLifRedemption"
                      alt={t("e+Life Redemption")}
                    >
                      {t("e+Life Redemption")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-parklist"
                      id="linkHeaderMenuLifeParkList"
                      alt={t("e+Life Park List")}
                    >
                      {t("e+Life Park List")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-leaderboard"
                      id="linkHeaderMenuLifeLeaderboard"
                      alt={t("e+life Leaderboard")}
                    >
                      {t("e+Life Leaderboard")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-faq"
                      id="linkHeaderMenuLifeFaq"
                      alt={t("e+Life faq")}
                    >
                      {t("e+Life faq")}
                    </NavLink>
                  </li>
                  {/*<li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-step-challenge"
                      id="linkHeaderMenuLifeStepChallenge"
                      alt={t("e+Life Step Challenge")}
                    >
                      {/* <span className="limited-event-tag">
                        {t("life step challenge tag")}
                      </span> 
                      {t("e+Life Step Challenge")}
                    </NavLink>
                  </li>*/}
                </ul>
              </div>

              <li>
                <NavLink
                  onClick={
                    isIe
                      ? this.handleIEClick
                      : () => {
                          this.closeMobileMenu();
                        }
                  }
                  exact
                  to="/privacy-protection"
                  id="linkHeaderMobilePrivacy"
                  alt={t("Tips on Privacy Protection & Security")}
                  tabIndex="0"
                  className={isIe ? "disabledMobileItem" : ""}
                >
                  {t("Tips on Privacy Protection & Security")}
                </NavLink>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/playlist?list=PLVHZGxYgzlTPv83cWdwiAbqIjJf6AZ-dQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="linkHeaderMobileMenuTutorials"
                  className={
                    isIe ? "disabledMobileItem" : "header-menu-item youtube"
                  }
                  alt={t("醫健通eHealth App Videos")}
                  tabIndex="0"
                  onClick={this.handleIEClick}
                >
                  {t("menu video item")}
                </a>
              </li>
              <li>
                <NavLink
                  onClick={
                    isIe
                      ? this.handleIEClick
                      : () => {
                          this.closeMobileMenu();
                        }
                  }
                  exact
                  to="/promulgation"
                  id="linkHeaderMobilePrivacy"
                  alt={t("Promulgation")}
                  tabIndex="0"
                  className={isIe ? "disabledMobileItem" : ""}
                >
                  {t("Promulgation")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={
                    isIe
                      ? this.handleIEClick
                      : () => {
                          this.closeMobileMenu();
                        }
                  }
                  exact
                  to="/ehealth-faq"
                  id="linkHeaderMobilePrivacy"
                  alt={t("FAQs")}
                  tabIndex="0"
                  className={isIe ? "disabledMobileItem" : ""}
                >
                  {t("FAQs")}
                </NavLink>
              </li>

              <div
                className="mobile-change-language"
                id="mobile-change-language"
                onTouchStart={(e) => {
                  this.onContianerAction(e);
                }}
                onClick={(e) => {
                  this.onContianerAction(e);
                }}
                tabIndex="0"
              >
                {mobileLanguageButtons}
              </div>
            </ul>
          </div>
        </header>

        <div className="promotion-nav" id="ProNav">
          <div className="main-view">
            <div className="promotion-logo">
              <a
                href="#"
                alt={t("eHealth Website")}
                className={isIe ? "disabledCursor" : "notDisabled"}
                onClick={
                  isIe
                    ? this.handleIEClick
                    : () => {
                        window.open(ehealthWesbiteUrl, "_blank");
                      }
                }
              >
                <img
                  lang="zh-HK"
                  src={load(`./resources/images/logo_color.png`)}
                  id="imgHeaderMenuLogo"
                  alt={t(
                    "Electronic Health Record Sharing System 醫健通 E health"
                  )}
                />
              </a>
            </div>
            <div className="header-menu-container">
              <NavLink
                onClick={this.handleIEClick}
                exact
                to="/index.html"
                id="linkHeaderMenuHome​"
                className={
                  isIe ? "disabled-header-menu-item" : "header-menu-item"
                }
                alt={t("Home")}
              >
                {t("Home")}
              </NavLink>

              <div
                className="dropdown"
                onMouseEnter={(e) =>
                  isIe ? this.closeMenu(e) : this.showMenu(e)
                }
                onMouseLeave={(e) => this.closeMenu(e)}
              >
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenu(e)
                      : this.state.showMenu
                      ? this.closeMenu(e)
                      : this.showMenu(e);
                  }}
                  exact
                  to="/index.html"
                  id="btnHeaderMenuOneStopService"
                  className={
                    isIe
                      ? "disabled-header-menu-item"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("Nav eHealth App")}
                >
                  {t("Nav eHealth App")} <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenu ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      exact
                      to="/health-management"
                      id="linkHeaderMenuHealthManagement​"
                      alt={t(
                        "Health Management​, click here for more information"
                      )}
                    >
                      {t("Health Management​")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/cross-boundary-health-record"
                      id="linkHeaderMobileMenuCBHR"
                      alt={t(
                        "Cross-boundary Health Record, click here for more information"
                      )}
                    >
                      {t("Cross-boundary Health Record")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/personal-folder"
                      id="linkHeaderMobileMenuPersonalFolder"
                      alt={t(
                        "Personal Folder, click here for more information"
                      )}
                    >
                      {t("Personal Folder")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/clinical-vaccine"
                      id="linkHeaderMenuVaccines"
                      alt={t("Vaccines, click here for more information")}
                    >
                      {t("Vaccines")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/clinical-appointment"
                      id="linkHeaderMenuAppointment"
                      alt={t("Appointment, click here for more information")}
                    >
                      {t("Appointment")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/clinical-allergy"
                      id="linkHeaderMenuAllergyAndMedications"
                      alt={t("Allergy, click here for more information")}
                    >
                      {t("Allergy")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/clinical-medication"
                      id="linkHeaderMenuAllergyAndMedications"
                      alt={t("Medications, click here for more information")}
                    >
                      {t("Medications")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/clinical-investigations"
                      id="linkHeaderMenuInvestigations"
                      alt={t("Investigations, click here for more information")}
                    >
                      {t("Investigations")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/health-news"
                      id="linkHeaderMenuHealthNews"
                      alt={t("Health news, click here for more information")}
                    >
                      {t("Health News")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/doctor-search"
                      id="linkHeaderMenuDoctorSearch"
                      alt={t("Doctor search, click here for more information")}
                    >
                      {t("Doctor Search")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/child-growth"
                      id="linkHeaderMenuChildGrowthRecord"
                      alt={t(
                        "Child growth record, click here for more information"
                      )}
                    >
                      {t("Child Growth Record")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/health-program"
                      id="linkHeaderMenuHealthProgramme"
                      alt={t(
                        "Health programme, click here for more information"
                      )}
                    >
                      {t("Health Programme")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/caregiver"
                      id="linkHeaderMenuMyFamily"
                      alt={t("My family, click here for more information")}
                    >
                      {t("My Family")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/my-family-doctor"
                      id="linkHeaderMenuMyFamilyDoctor"
                      alt={t(
                        "My Family Doctor, click here for more information"
                      )}
                    >
                      {t("My Family Doctor")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/healthcare-document"
                      id="linkHeaderMenuHealthcareDocument"
                      alt={t(
                        "Healthcare document, click here for more information"
                      )}
                    >
                      {t("Healthcare Document")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/medical-certificate"
                      id="linkHeaderMenuMedicalCertificate"
                      alt={t(
                        "Medical Certificate, click here for more information"
                      )}
                    >
                      {t("Medical Certificate")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/qrcode-licence-scanner"
                      id="linkHeaderMenuQRcodeScanner"
                      alt={t(
                        "QR Code / Licence Scanner, click here for more information"
                      )}
                    >
                      {t("QR Code / Licence Scanner main menu")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div
                className="dropdown"
                onMouseEnter={(e) =>
                  isIe ? this.closeMenuPro(e) : this.showMenuPro(e)
                }
                onMouseLeave={(e) => this.closeMenuPro(e)}
              >
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenuPro(e)
                      : this.state.showMenuPro
                      ? this.closeMenuPro(e)
                      : this.showMenuPro(e);
                  }}
                  exact
                  to="/index.html"
                  id=""
                  className={
                    isIe
                      ? "disabledMobileItem"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("eHealth Pro")}
                >
                  {t("eHealth Pro")} <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenuPro ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro"
                      id="linkHeaderMenuEhealthPro"
                      role="text"
                      aria-label={t("Pro Overview")}
                      alt={t("Pro Overview")}
                    >
                      {t("Pro Overview")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro-account-management"
                      id="linkHeaderMenuEhealthProAccMgmt"
                      role="text"
                      aria-label={t("Pro Account Management")}
                      alt={t("Pro Account Management")}
                    >
                      {t("Pro Account Management")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro-information-sharing"
                      id="linkHeaderMenuEhealthProInfoShare"
                      role="text"
                      aria-label={t("Pro Information Sharing")}
                      alt={t("Pro Information Sharing")}
                    >
                      {t("Pro Information Sharing")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/ehealth-pro-notification-center"
                      id="linkHeaderMenuEhealthNotiCenter"
                      role="text"
                      aria-label={t("Pro Notification Center")}
                      alt={t("Pro Notification Center")}
                    >
                      {t("Pro Notification Center")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div
                className="dropdown"
                onMouseEnter={(e) =>
                  isIe ? this.closeMenuLife(e) : this.showMenuLife(e)
                }
                onMouseLeave={(e) => this.closeMenuLife(e)}
              >
                <NavLink
                  onClick={(e) => {
                    isIe
                      ? this.closeMenuLife(e)
                      : this.state.showMenuLife
                      ? this.closeMenuLife(e)
                      : this.showMenuLife(e);
                  }}
                  exact
                  to="/index.html"
                  id=""
                  className={
                    isIe
                      ? "disabledMobileItem"
                      : "mobile-has-dropdown header-menu-item"
                  }
                  tabIndex="0"
                  alt={t("Nav e+Life App")}
                >
                  {t("Nav e+Life App")} <span className="arrow-down"></span>
                </NavLink>
                <ul
                  style={{
                    display: this.state.showMenuLife ? "block" : "none",
                  }}
                  className="dropdown-menu"
                >
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-overview"
                      id="linkHeaderMenuLifeOveriew"
                      role="text"
                      aria-label={t("e+Life Overview")}
                      alt={t("e+Life Overview")}
                    >
                      {t("e+Life Overview")}
                    </NavLink>
                  </li>
                  <li className="no-active-status">
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-overview#overview-park-container"
                      id="linkHeaderMenuLifeOveriewPark"
                      role="text"
                      aria-label={t("e+ Go to Park")}
                      alt={t("e+ Go to Park")}
                    >
                      {t("e+ Go to Park")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-tutorial"
                      id="linkHeaderMenuLifeTutorial"
                      role="text"
                      aria-label={t("e+Life Tutorial")}
                      alt={t("e+Life Tutorial")}
                    >
                      {t("e+Life Tutorial")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-coins"
                      id="linkHeaderMenuLifeCoins"
                      role="text"
                      aria-label={t("e+Life Coins")}
                      alt={t("e+Life Coins")}
                    >
                      {t("e+Life Coins")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-redemption"
                      id="linkHeaderMenuLifRedemption"
                      className="life-redemption"
                      role="text"
                      aria-label={t("e+Life Redemption")}
                      alt={t("e+Life Redemption")}
                    >
                      {t("e+Life Redemption")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-parklist"
                      id="linkHeaderMenuLifeParkList"
                      role="text"
                      aria-label={t("e+Life Park List")}
                      alt={t("e+Life Park List")}
                    >
                      {t("e+Life Park List")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-leaderboard"
                      id="linkHeaderMenuLifeLeaderboard"
                      role="text"
                      aria-label={t("e+Life Leaderboard")}
                      alt={t("e+Life Leaderboard")}
                    >
                      {t("e+Life Leaderboard")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-faq"
                      id="linkHeaderMenuLifeFaq"
                      role="text"
                      aria-label={t("e+Life faq")}
                      alt={t("e+Life faq")}
                    >
                      {t("e+Life faq")}
                    </NavLink>
                  </li>
                  {/* <span className="limited-event-tag">
                    {t("life step challenge tag")}
                  </span> */}
                  {/*<li className="separate-menu-item">
                    <NavLink
                      onClick={() => this.closeMobileMenu()}
                      exact
                      to="/elife-step-challenge"
                      id="linkHeaderMenuLifeStepChallenge"
                      className="life-step-challenge"
                      role="text"
                      aria-label={t("e+Life Step Challenge")}
                      alt={t("e+Life Step Challenge")}
                    >
                      {t("e+Life Step Challenge")}
                    </NavLink>
                </li>*/}
                </ul>
              </div>
              <NavLink
                onClick={this.handleIEClick}
                exact
                to="/privacy-protection"
                id="linkHeaderMenuPrivacy"
                className={
                  isIe ? "disabled-header-menu-item" : "header-menu-item"
                }
                alt={t("Tips on Privacy Protection & Security")}
              >
                {t("Tips on Privacy Protection & Security")}
              </NavLink>

              <a
                href="https://www.youtube.com/playlist?list=PLVHZGxYgzlTPv83cWdwiAbqIjJf6AZ-dQ"
                target="_blank"
                rel="noopener noreferrer"
                id="linkHeaderMenuTutorials"
                className={
                  isIe ? "disabled-header-menu-item" : "header-menu-item"
                }
                alt={t("醫健通eHealth App Videos")}
                tabIndex="0"
                onClick={this.handleIEClick}
              >
                {" "}
                {t("menu video item")}
              </a>

              <div className="header-menu-item">
                <NavLink
                  onClick={() => this.closeMobileMenu()}
                  exact
                  to="/promulgation"
                  id="linkHeaderMenuPromulgation​"
                >
                  {t("Promulgation")}
                </NavLink>
              </div>

              <div className="header-menu-item">
                <NavLink
                  onClick={() => this.closeMobileMenu()}
                  exact
                  to="/ehealth-faq"
                  id="linkHeaderMenuPromulgation​"
                >
                  {t("FAQs")}
                </NavLink>
              </div>
            </div>

            <div className="change-language">{languageButtons}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PageHOC(NavigationMenu);
